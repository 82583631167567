.hs_rs_videoview_list{
    height: 130vh;
    overflow-x: hidden;
    overflow-y: scroll;
    max-height: 130vh;
    min-height: 130vh;
}
.hs_rs_videoview_list::-webkit-scrollbar-track
{
	background-color: #F5F5F5;
	border-radius: 10px;
}

.hs_rs_videoview_list::-webkit-scrollbar
{
	width: 10px;
	background-color: #F5F5F5;
}

.hs_rs_videoview_list::-webkit-scrollbar-thumb
{
	border-radius: 10px;
	background-color:#0eacc5;
}

.hs_rs_videoview_list_wrapper {
    float: left;
    width: 100%;
    padding-bottom: 15px;
    border-bottom: 1px dashed #cecece;
    margin-bottom: 15px;
    -webkit-transition: all 0.5s;
    -o-transition: all 0.5s;
    -ms-transition: all 0.5s;
    -moz-transition: all 0.5s;
    transition: all 0.5s;
    cursor: pointer;
}
.hs_rs_videoview_list_img_wrapper {
    float: left;
    width: 135px;
    overflow: hidden;
}
.hs_rs_videoview_list_img_wrapper a{
    min-height: 78px;
    display: block;
    position: relative;
    overflow: hidden;
}
.hs_rs_videoview_list_img_wrapper img{

    max-width: 100%;
    vertical-align: middle;
    min-height: 110px;
    position: absolute;
    left: 0;
    height: 110px;
    top: -18px;
    width: 135px;
}
.hs_rs_videoview_list_img_wrapper .react-thumbnail-generator .snapshot-generator{
    max-width: 100%;
    vertical-align: middle;
    min-height: 110px;
    position: absolute;
    left: 0;
    height: 110px;
    top: -18px;
    width: 135px;
}
.hs_rs_videoview_list_single_wrapper {
    float: left;
    width: calc(100% - 135px);
    padding-left: 10px;
    padding-top: 0px;
}
.hs_rs_videoview_list_single_wrapper h2 {
    font-size: 13px;
    font-weight: 500;
    color: #000000;
    line-height: 19px;
    max-height: 58px;
    overflow: hidden;
    -webkit-transition: all 0.5s;
    -o-transition: all 0.5s;
    -ms-transition: all 0.5s;
    -moz-transition: all 0.5s;
    transition: all 0.5s;
}
.hs_rs_videoview_list_single_wrapper h2 a {
    font-size: 13px;
    font-weight: 500;
    color: #000000;
    line-height: 19px;
    max-height: 58px;
    overflow: hidden;
    -webkit-transition: all 0.5s;
    -o-transition: all 0.5s;
    -ms-transition: all 0.5s;
    -moz-transition: all 0.5s;
    transition: all 0.5s;
}
.hs_rs_videoview_list_single_wrapper p {
    padding-top: 10px;
    -webkit-transition: all 0.5s;
    -o-transition: all 0.5s;
    -ms-transition: all 0.5s;
    -moz-transition: all 0.5s;
    transition: all 0.5s;
}

.ytd-video-meta-block{
    font-size: 11px;
}

.ytd-video-meta-block span:last-child{
    margin-left: 10px;
}

.hs_rs_videoview_list_img_wrapper .video-icon{
    top: 22px;
    left: 48px;
    margin-top: 0;
    margin-left: 0;
    z-index: 1;
    width: 36px;
    height: 36px;
}

.hs_rs_videoview_list_img_wrapper .video-icon:after {
    border-width: 8px 14px;
    margin-top: -8px;
    margin-left: -5px;
}

.hs_rs_videoview_list_wrapper:hover .video-icon {
    background-color: #C00;
}

.hs_rs_videoview_list_wrapper:hover .video-icon:before {
    display: block;
    animation: lds-dual-ring 1.2s linear infinite;
}

.hs_rs_videoview_list_wrapper:hover .video-icon:after{
    border-left-color: rgb(255 255 255);
}

.hs_rs_videoview_list .widget-title {
    padding: 0;
    margin-bottom: 15px;
    margin-top: 0px;
    position: relative;
    text-transform: uppercase;
    padding-bottom: 12px;
    font-weight: 700;
    font-size: 14px;
}
.hs_rs_videoview_list .widget-title .is-arrow-ct {
    padding-right: 50px;
    display: block;
}
.hs_rs_videoview_list .widget-title>span:not(.widget-arrow):after {
    content: '';
    display: block;
    width: 40px;
    height: 3px;
    background-color: #C00;
    position: absolute;
    bottom: -1px;
    left: 0;
    z-index: 1;
}
.hs_rs_videoview_list .widget-title:after {
    content: '';
    display: block;
    width: 100%;
    height: 1px;
    background-color: #EAEAEA;
    position: absolute;
    bottom: 0;
    left: 0;
}

.hs_rs_videoview_list_single_wrapper .post-meta-wrap{
    font-size: 12px;
}
.hs_rs_videoview_list_single_wrapper .post-meta-wrap i{
    margin-right: 5px;
}

#video-list .card .delete-icon{
    min-width: 26px;
    padding: 0 7px 5px 7px;
    display: block;
    background-color: #111;
    text-align: center;
    position: absolute;
    right: 0px;
    top: 18px;
    cursor: pointer;
    color: #FFF;
    max-width: 26px;
    overflow: hidden;
    white-space: nowrap;
    backface-visibility: hidden;
    -webkit-backface-visibility: hidden;
    transform: translate3d(0,0,0);
    -webkit-transform: translate3d(0,0,0);
    -ms-transform: translate3d(0,0,0);
    transition: background-color 0.3s ease-in-out,max-width 0.3s ease-in-out,opacity 0.3s,visibility 0.3s;
    -webkit-transition: background-color 0.3s ease-in-out,max-width 0.3s ease-in-out,opacity 0.3s,visibility 0.3s;
}
#video-list .card .delete-icon:hover {
    color: #FFF;
    background-color: #C00;
    max-width: 150px;
}
#video-list .card .delete-icon i.fa {
    line-height: 1;
    display: inline-block;
    vertical-align: middle;
    margin-right: 8px;
    font-size: 14px;
}
#video-list .card .delete-icon .watch-text {
    display: inline-block;
    vertical-align: middle;
    line-height: 1;
}







#video-list .card .publish-icon{
    min-width: 26px;
    padding: 0 7px 5px 7px;
    display: block;
    background-color: #111;
    text-align: center;
    position: absolute;
    right: 0px;
    top: 50px;
    cursor: pointer;
    color: #FFF;
    max-width: 26px;
    overflow: hidden;
    white-space: nowrap;
    backface-visibility: hidden;
    -webkit-backface-visibility: hidden;
    transform: translate3d(0,0,0);
    -webkit-transform: translate3d(0,0,0);
    -ms-transform: translate3d(0,0,0);
    transition: background-color 0.3s ease-in-out,max-width 0.3s ease-in-out,opacity 0.3s,visibility 0.3s;
    -webkit-transition: background-color 0.3s ease-in-out,max-width 0.3s ease-in-out,opacity 0.3s,visibility 0.3s;
}
#video-list .card .publish-icon:hover {
    color: #FFF;
    background-color: #08b101;
    max-width: 150px;
}
#video-list .card .publish-icon i.fa {
    line-height: 1;
    display: inline-block;
    vertical-align: middle;
    margin-right: 8px;
    font-size: 14px;
}
#video-list .card .publish-icon .watch-text {
    display: inline-block;
    vertical-align: middle;
    line-height: 1;
}








#video-list .card .unpublish-icon{
    min-width: 26px;
    padding: 0 7px 5px 7px;
    display: block;
    background-color: #111;
    text-align: center;
    position: absolute;
    right: 0px;
    top: 50px;
    cursor: pointer;
    color: #FFF;
    max-width: 26px;
    overflow: hidden;
    white-space: nowrap;
    backface-visibility: hidden;
    -webkit-backface-visibility: hidden;
    transform: translate3d(0,0,0);
    -webkit-transform: translate3d(0,0,0);
    -ms-transform: translate3d(0,0,0);
    transition: background-color 0.3s ease-in-out,max-width 0.3s ease-in-out,opacity 0.3s,visibility 0.3s;
    -webkit-transition: background-color 0.3s ease-in-out,max-width 0.3s ease-in-out,opacity 0.3s,visibility 0.3s;
}
#video-list .card .unpublish-icon:hover {
    color: #FFF;
    background-color: #C00;
    max-width: 150px;
}
#video-list .card .unpublish-icon i.fa {
    line-height: 1;
    display: inline-block;
    vertical-align: middle;
    margin-right: 8px;
    font-size: 14px;
}
#video-list .card .unpublish-icon .watch-text {
    display: inline-block;
    vertical-align: middle;
    line-height: 1;
}