.hs_num_video_view_wrapper {
    padding: 0px 0px;
}
.videotag{
    width: 100%;
    border: none;

}

.box {
    padding: 15px 0px;
    transition-duration: 0.4s;
    margin-bottom: 0px!important;
}
.video-block .mb-3 {
    margin-bottom: 30px !important;
}
.single-video-title h2 {
    font-size: 22px;
    margin-bottom: 8px;
}
.single-video-title h2 a {
    color: #000;
    line-height: 25px;
    font-size: 20px;
}


.single-video-info-content h6 {
    font-size: 15px;
    font-weight: 600;
    margin-bottom: 7px;
}
.single-video-info-content p{
    margin-bottom: 20px;
    font-size: 14px;
}
.single-video-author img {
    border-radius: 50px;
    float: left;
    height: 38px;
    margin: 0 13px 0 0;
    width: 38px;
    max-width: 100%;
}
.single-video-author p {
    margin: 0;
    padding: 0;
    line-height: 15px;
}
.single-video-author p a{
    color: #1c9bb5;
}
.single-video-author .small, .single-video-author small {
    font-size: 80%;
    font-weight: 400;
    color: #000;
}
.single-video-author .btn-outline-danger{
    color: #ff253a;
    background: none;
    border: none;
}
.single-video-author .btn-outline-danger i{
    font-size: 25px;
}
.single-video-author .btn-outline-danger:hover, .single-video-author .btn-outline-danger:not(:disabled):not(.disabled):active{
    background: none;
    color: #ff253a;
    border: none;
}

.single-video-author .btn-outline-danger span{
    display: block;
    font-size: 12px;
}
.single-video-author .btn-outline-danger .fa-heart{
    display: block;
}

.single-video-author .btn-outline-danger .fa-heart-o{
    display: block;
}


.single-video-des br{
    display: none;
}
.single-video-des.box{
    padding: 0px;
}
.single-video-des .content {
    font-size: 14px;
    padding: 0px;
    text-align: justify;
}
.single-video-des .my-anchor-css-class{
    color: #1c9bb5;
    display: block;
    font-weight: 500;
}
.single-video-share span a{
    background: #ccc none repeat scroll 0 0;
    color: #fff;
    display: inline-block;
    font-size: 18px;
    width: 32px;
    height: 32px;
    margin-right: 10px;
    text-align: center;
    line-height: 32px;
}
.single-video-share span a:hover{
        background: #000 none repeat scroll 0 0;

}





@media (max-width: 767px){
.videotag {
    height: auto!important;
    position: fixed;
    left: 0;
    right: 0;
    top: 66px;
    bottom: auto;
    z-index: 7;
}
.player-size{
    position: relative;
    width: 100%;
    padding-bottom: 58.25%;
    overflow: hidden;
    background-color: #000;
}
.hs_rs_videoview_list{
    padding: 0px 15px;
}

.hs_rs_videoview_list{
    height: auto;
    max-height: none;
    min-height: auto;
}





}


