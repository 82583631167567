.login-page .box h3, .register-page .box h3 {
    font-size: 16px;
    font-weight: bold;
    text-transform: uppercase;
    margin: 20px 0;
}
.login-page .box p, .register-page .box p
{
    font-size: 13px;
    line-height: 1.5;
    margin-bottom: 15px;
}

.login-page .btn, .register-page .btn {
    -moz-user-select: none;
    -ms-user-select: none;
    -webkit-user-select: none;
    user-select: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    display: inline-block;
    width: auto;
    height: auto;
    text-decoration: none;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    border: 1px solid transparent;
    border-radius: 0;
    padding: 8px 15px 8px;
    background-color: #000;
    color: #fff;
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    text-transform: uppercase;
    letter-spacing: 1px;
    line-height: normal;
    white-space: normal;
    font-size: 13px;
    -ms-transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}
.btn:hover, .btn:focus {
    background-color: #1a1a1a;
    opacity: 0.8;
    color: #fff;
    text-decoration: none;
}
.form-group {
    margin-bottom: 1rem;
}

label {
    margin-bottom: 3px;
    display: inline-block;
    font-size: 13px;
    line-height: 1.5;
    color: #111111;
    font-weight: 400;
}

.login-page form .required, .register-page .required {
    color: #F00;
}

.contact-form input {
    height: 40px;
    padding: 0 10px;
    width: 100%;
    font-size: 14px;
    box-shadow: none;
    -webkit-box-shadow: none;
    border-radius: 0;
    -webkit-border-radius: 0;
    background: transparent;
    border: 1px solid #d7d7d7;
    transition: all 0.4s ease-out 0s;
    -webkit-transition: all 0.4s ease-out 0s;
    color: #424242;
}



.mb-3, .my-3 {
    margin-bottom: 1rem!important;
}
.login-page{
    float: left;
    width: 100%;
}
.page-title h1 {
    letter-spacing: normal;
    font-weight: 700;
    font-size: 20px;
    text-transform: uppercase;
    position: relative;
    margin: 30px 0 0px 0;
    text-align: center;
}