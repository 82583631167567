#video-list .ml-10{
    margin-left: 10px;
}

#video-list img{
    height: 200px;
    object-fit: cover;
}
#video-list .card .card-title {
    font-weight: 700;
    height: 32px;
    overflow: hidden;
    font-size: 13px!important;
    color: #000;  
}
#video-list .card a{
    position: relative;
}
#video-list  p{
    margin-bottom: 0px;
}
#video-list .video-icon {
    width: 66px;
    height: 66px;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    background-color: rgba(0,0,0,.5);
    position: absolute;
    z-index: 1;
    display: none;
    top: 50%;
    left: 50%;
    margin-top: -33px;
    margin-left: -33px;
    cursor: pointer;
    transition: background-color 0.3s,opacity 0.3s,visibility 0.3s,border-color 0.3s;
    -webkit-transition: background-color 0.3s,opacity 0.3s,visibility 0.3s,border-color 0.3s;
    backface-visibility: hidden;
    -webkit-backface-visibility: hidden;
    transform: translate3d(0,0,0);
    -webkit-transform: translate3d(0,0,0);
    -ms-transform: translate3d(0,0,0);
}
#video-list .video-icon:after {
    content: '';
    display: block;
    position: absolute;
    width: 0;
    height: 0;
    border: solid transparent;
    border-width: 15px 25px;
    border-left-color: rgba(255,255,255,.5);
    top: 50%;
    left: 50%;
    margin-top: -15px;
    margin-left: -8px;
    transition: border-color 0.3s;
    -webkit-transition: border-color 0.3s;
    backface-visibility: hidden;
    -webkit-backface-visibility: hidden;
    transform: translate3d(0,0,0);
    -webkit-transform: translate3d(0,0,0);
    -ms-transform: translate3d(0,0,0);
}
#video-list .video-icon:before {
    content: '';
    display: none;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    border: 3px solid transparent;
    border-top-color: #fff;
    border-right-color: rgba(255,255,255,.68);
    border-bottom-color: #fff;
    border-left-color: rgba(255,255,255,.68);
    border-radius: 50%;
    -webkit-border-radius: 50%;
}
#video-list .card:hover .video-icon:before {
    display: block;
    animation: lds-dual-ring 1.2s linear infinite;
}
#video-list .card:hover .video-icon {
    background-color: #C00;
}
#video-list .card .video-icon:hover {
    animation: videoiconclick 1.5s infinite ease-in-out;
    -webkit-animation: videoiconclick 1.5s infinite ease-in-out;
}
#video-list .card .video-icon:after{
    border-left-color: rgb(255 255 255);
}


#video-list .post-meta-wrap{
    font-size: 12px;
}
#video-list .author.vcard {
    display: block;
    margin-right: 15px;
    margin-bottom: 5px;
    color: #1e9ff2;
}
#video-list .date {
    display: inline-block;
    margin-right: 15px;
}
#video-list .view-count{
    display: inline-block;
    margin-right: 15px;
}
#video-list .like-count {
    display: inline-block;
    margin-right: 15px;
}

#video-list .author.vcard i.fa {
    padding-right: 5px;
}
#video-list .date i.fa {
    padding-right: 5px;
}
#video-list .view-count i.fa {
    padding-right: 5px;
}
#video-list .like-count i.fa {
    padding-right: 5px;
}


#video-list .snapshot-generator:first-child{
    display: none;
  }
  
  #video-list .snapshot-generator{
    
      width: 100%;
      height: 180px;
  
  }
